<template>
  <div class="d-flex flex-column flex-grow-1 rounded mr-md-3">
    <BaseToolBar title="Chiffrages" subtitle="Liste des chiffrages disponibles">
      <BaseButton
        class="btn btn-primary"
        v-tooltip="'Ajouter une phase'"
        @click="createPhase(id)"
        :loading="loadingPhaseCreate"
        text="Ajouter"
        icon="plus"
      ></BaseButton>
    </BaseToolBar>
    <div class="recap_affaire mb-3 p-2 shadow-sm rounded" :style="{ backgroundColor: bg }">
      <div class="d-flex flex-column flex-md-row align-items-center">
        <h3 class="m-0 ml-md-3">Récap affaire</h3>
        <div class="ml-md-auto rounded p-md-3">
          <h3>{{ total.toLocaleString() }} €</h3>
          <div>
            <small>Prix de vente total</small>
          </div>
        </div>
        <div class="ml-md-2 rounded p-md-3">
          <h3>{{ moyenne.toFixed(0) }} %</h3>
          <div>
            <small>Marge moyenne</small>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row w-100" v-if="phases">
      <div class="flex-grow-1 d-flex flex-column flex-md-row flex-wrap mb-3">
        <div v-for="phase in phases" :key="phase.id" class="w-100">
          <div class="d-flex align-items-center justify-content-between mt-4 mb-1">
            <h6 class="mb-0 ml-2" v-if="phase.text">{{ phase.text }}</h6>
            <h6 class="mb-0 ml-2" v-if="!phase.text">Phase sans titre</h6>
            <div class="d-flex align-items-center">
              <div>
                <small>
                  <strong>total : {{ getPhaseTotalchiffrages(phase.id).toFixed(2) }} €</strong>
                </small>
              </div>
              <v-popover>
                <ButtonCircle
                  size="16"
                  name="edit"
                  v-tooltip="'modifier la phase'"
                  :loading="loadingPhaseUpdate"
                ></ButtonCircle>
                <template slot="popover">
                  <div class="text-left text-white mb-3" style="min-width:300px;">
                    <base-input
                      class="mb-4"
                      inputText="Description"
                      v-model="phase.text"
                      darkBackgroundMode
                    ></base-input>
                    <base-input-date-picker
                      class="mb-4"
                      inputText="Date début"
                      v-model="phase.start_date"
                      darkBackgroundMode
                    ></base-input-date-picker>
                    <base-input-number
                      v-model="phase.duration"
                      inputText="Durée"
                      darkBackgroundMode
                    ></base-input-number>
                  </div>
                  <div class="text-right">
                    <button class="btn btn-primary btn-sm" @click="updatePhase(phase)">Enregistrer</button>
                  </div>
                </template>
              </v-popover>
              <ButtonCircle
                size="16"
                name="trash"
                @click="deletePhase(phase)"
                :loading="loadingPhaseDelete"
                v-tooltip="'Supprimer la phase'"
              ></ButtonCircle>
              <ButtonCircle
                size="16"
                name="plus"
                @click="createChiffrage(phase.id)"
                :loading="loadingChiffrageCreate"
                v-tooltip="'Ajouter un chiffrage'"
              ></ButtonCircle>
            </div>
          </div>
          <div v-if="chiffrages">
            <div v-for="chiffrage in chiffrages" :key="chiffrage.id" class="w-100">
              <chiffrage-item
                v-if="chiffrage.chiffrage_phase_id === phase.id"
                :chiffrage="chiffrage"
                :lists="lists"
                :loading="loadingChiffrageUpdate || loadingChiffrageDelete"
                :statut="statut"
                :data="data"
                @delete="deleteChiffrage(chiffrage.id)"
                @store="updateChiffrage(chiffrage.id)"
              ></chiffrage-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import { cloneObj } from "@/helpers.js";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import ChiffrageItem from "@/components/affaires/ChiffrageItem.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseButton from "@/components/bases/Button.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";

export default {
  components: {
    BaseToolBar,
    ChiffrageItem,
    ButtonCircle,
    BaseInput,
    BaseInputDatePicker,
    BaseButton,
    BaseInputNumber
  },
  data() {
    return {
      loading: false,
      loadingChiffrageCreate: false,
      loadingChiffrageUpdate: false,
      loadingChiffrageDelete: false,
      loadingPhaseCreate: false,
      loadingPhaseUpdate: false,
      loadingPhaseDelete: false,
      statut: "",
      lists: {
        contrats: [
          { id: 1, description: "cadre" },
          { id: 2, description: "etam" }
        ]
      },
      feedback: {},
      //newPhase: {},
      data: []
    };
  },
  methods: {
    ...mapActions({
      _createPhase: "affaires/createPhase",
      _updatePhase: "affaires/updatePhase",
      _deletePhase: "affaires/deletePhase",
      _loadChiffrages: "chiffrages/load",
      _createChiffrage: "chiffrages/create",
      _updateChiffrage: "chiffrages/update",
      _deleteChiffrage: "chiffrages/delete"
    }),
    createPhase(affaire_id) {
      this.loadingPhaseCreate = true;
      this._createPhase(affaire_id)
        //.then(response => (this.newPhase = response.data))
        .finally(() => (this.loadingPhaseCreate = false));
    },
    updatePhase(phase) {
      this.loadingPhaseUpdate = true;
      this._updatePhase(phase).finally(() => (this.loadingPhaseUpdate = false));
    },
    deletePhase(phase) {
      this.loadingPhaseDelete = true;
      this._deletePhase(phase).finally(() => (this.loadingPhaseDelete = false));
    },
    createChiffrage(phase_id) {
      this.loadingChiffrageCreate = true;
      this._createChiffrage(phase_id).finally(
        () => (this.loadingChiffrageCreate = false)
      );
    },
    updateChiffrage(id) {
      this.loadingChiffrageUpdate = true;
      this._updateChiffrage(id).finally(
        () => (this.loadingChiffrageUpdate = false)
      );
    },
    deleteChiffrage(id) {
      this.loadingChiffrageDelete = true;
      this._deleteChiffrage(id).finally(
        () => (this.loadingChiffrageDelete = false)
      );
    },
    getPhaseTotalchiffrages(id) {
      let total = 0;
      let _chiffrages = this.chiffrages.filter(
        chiffrage => chiffrage.chiffrage_phase_id === id
      );
      _chiffrages.map(chiffrage => (total = total + chiffrage.prixVente));
      return total;
    }
  },
  computed: {
    ...mapGetters({
      total: "chiffrages/prixVenteTotal",
      moyenne: "chiffrages/margeMoyenne"
    }),
    ...mapFields("affaires", ["affaire.id"]),
    ...mapMultiRowFields("chiffrages", ["chiffrages"]),
    ...mapMultiRowFields("affaires", ["phases"]),
    bg() {
      return this.$store.getters["colors/colors"].color3;
    }
  },
  mounted() {}
};
</script>
