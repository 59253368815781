<template>
  <container-list-extend title="Contrat" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <div v-if="chiffrage.description">
          {{ chiffrage.description }}
          <div class="text-muted">
            <small>jours prévus : {{ chiffrage.jours_prevus }}</small>
          </div>
        </div>
        <div class="text-primary" v-if="!chiffrage.description">Nouveau chiffrage</div>
        <div class="ml-auto d-flex align-items-center">
          <div class="d-flex mr-3">
            <button
              class="btn btn-outline-primary btn-sm mr-2"
              @click="mode = 1"
              :class="{ active: mode === 1 }"
            >AT</button>
            <button
              v-if="havePermission('get_collaborateur_contrat_parametres')"
              class="btn btn-outline-primary btn-sm mr-2"
              @click="mode = 2"
              :class="{ active: mode === 2 }"
            >Projet</button>
            <button
              class="btn btn-outline-primary btn-sm mr-2"
              @click="mode = 3"
              :class="{ active: mode === 3 }"
            >Autre</button>
          </div>
          <v-popover>
            <div>
              <div>
                <div>
                  <small>prix de vente : {{ prixVente.toLocaleString() }} €</small>
                </div>
              </div>
              <div>
                <small>
                  Marge : {{ margeBruteEuros.toLocaleString() }} € ({{
                  margeBrute.toLocaleString()
                  }}%)
                </small>
              </div>
            </div>
            <template slot="popover">
              <div class="text-left" style="max-width:450px;">
                <h5 class="m-0 text-primary">Calculs</h5>
                <base-form-row>
                  <small>
                    <div v-if="salaireCharge">
                      Salaire chargé :
                      {{ salaireCharge.toLocaleString() }} €
                    </div>
                    <div v-if="prixRevient">
                      Prix de revient par jour :
                      {{ prixRevient.toLocaleString() }} €
                    </div>
                    <div v-if="prixRevientTotal">
                      Prix de revient :
                      {{ prixRevientTotal.toLocaleString() }} €
                    </div>
                    <div v-if="prixVente">
                      Prix de vente :
                      {{ prixVente.toLocaleString() }} €
                    </div>
                    <div v-if="prixVente">
                      Prix de vente par jour :
                      {{ (prixVente / chiffrage.jours_prevus).toLocaleString() }} €
                    </div>
                    <div v-if="margeBrute && prixRevientTotal">
                      Marge :
                      <span class="text-danger" v-if="margeBrute < 20">
                        {{ margeBrute.toLocaleString() }} % ({{ margeBruteEuros }}
                        €)
                      </span>
                      <span
                        class="text-warning"
                        v-if="margeBrute >= 20 && margeBrute <= 25"
                      >{{ margeBrute }} % ({{ margeBruteEuros }} €)</span>
                      <span class="text-success" v-if="margeBrute > 25">
                        {{ margeBrute.toLocaleString() }} % ({{ margeBruteEuros }}
                        €)
                      </span>
                    </div>
                    <div v-if="margeBruteEuros">
                      Marge par jour :
                      {{
                      (margeBruteEuros / chiffrage.jours_prevus).toLocaleString()
                      }}
                      €
                    </div>
                  </small>
                </base-form-row>
              </div>
            </template>
          </v-popover>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <base-form @click="store" deleteBtn @delete="$emit('delete')" :loading="loading">
        <div class="w-100 mr-md-5" v-if="mode == 1">
          <base-form-row row>
            <base-input
              v-model="chiffrage.description"
              inputText="Libellé"
              :errors="feedback.description"
            ></base-input>
            <base-select
              inputText="Type"
              v-model.number="chiffrage.contrat_id"
              :options="lists.contrats"
              :errors="feedback.contrat_id"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-input-number v-model="chiffrage.salaire_annuel" inputText="Salaire annuel (€)"></base-input-number>
            <base-input-number
              v-model="chiffrage.indeminite_journaliere"
              inputText="Indemnités par jour (€)"
              :errors="feedback.indeminite_journaliere"
            ></base-input-number>
          </base-form-row>
          <base-form-row row>
            <base-input-number
              v-model="chiffrage.jours_prevus"
              inputText="Nombre de jours prévus"
              :errors="feedback.jours_prevus"
            ></base-input-number>
            <base-input-number
              v-model="chiffrage.taux_journalier"
              inputText="Taux journalier (€)"
              :errors="feedback.taux_journalier"
            ></base-input-number>
          </base-form-row>
          <base-form-row row>
            <base-input-number
              v-model="chiffrage.frais_divers"
              inputText="Frais répercuté(s)"
              :errors="feedback.frais_divers"
            ></base-input-number>
            <base-input-number
              v-model="chiffrage.frais_directs"
              inputText="Frais ajoutés au prix de vente"
              :errors="feedback.frais_directs"
            ></base-input-number>
          </base-form-row>
        </div>
        <div
          class="w-100 mr-md-5"
          v-if="
              mode == 2 &&
                havePermission('get_collaborateur_contrat_parametres')
            "
        >
          <base-form-row row>
            <base-input
              v-model="chiffrage.description"
              inputText="Libellé"
              :errors="feedback.description"
            ></base-input>
          </base-form-row>
          <base-form-row row>
            <SelectCollaborateur
              v-model.number="chiffrage.chiffrage_collaborateur_id"
              @collabSelected="selectCollab"
            ></SelectCollaborateur>
          </base-form-row>
          <base-form-row row>
            <BaseSlideNumber
              v-model.number="chiffrage.jours_prevus"
              text="Nombre de jours prévus"
              :min="0"
              :max="1000"
            ></BaseSlideNumber>
          </base-form-row>
          <base-form-row row>
            <BaseSlideNumber
              v-model.number="chiffrage.taux_journalier"
              text="Taux journalier (€)"
              :min="0"
              :max="1000"
            ></BaseSlideNumber>
          </base-form-row>
          <base-form-row row>
            <BaseSlideNumber
              v-model.number="chiffrage.indeminite_journaliere"
              text="indeminté journalière (€)"
              :min="0"
              :max="1000"
            ></BaseSlideNumber>
          </base-form-row>
          <base-form-row row>
            <base-input-number
              v-model="chiffrage.frais_divers"
              inputText="Frais répercuté(s)"
              :errors="feedback.frais_divers"
              :min="0"
              :max="1000"
            ></base-input-number>
            <base-input-number
              v-model="chiffrage.frais_directs"
              inputText="Frais ajoutés au prix de vente"
              :errors="feedback.frais_directs"
              :min="0"
              :max="10000"
            ></base-input-number>
          </base-form-row>
        </div>
        <div class="w-100 mr-md-5" v-if="mode == 3">
          <base-form-row row>
            <base-input
              v-model="chiffrage.description"
              inputText="Libellé"
              :errors="feedback.description"
            ></base-input>
          </base-form-row>
          <base-form-row row>
            <base-input-number
              v-model="chiffrage.frais_directs"
              inputText="Frais ajoutés au prix de vente"
              :errors="feedback.frais_directs"
            ></base-input-number>
          </base-form-row>
        </div>
        <base-form-row>
          <base-input-area
            v-model="chiffrage.details"
            text="Informations"
            :errors="feedback.details"
          ></base-input-area>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { filterFloat } from "@/helpers.js";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
import BaseSlideNumber from "@/components/bases/SlideNumber.vue";
import BaseSelect from "@/components/bases/Select.vue";
//import Uploader from "@/components/bases/Uploader.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    BaseInputNumber,
    BaseSelect,
    //Uploader,
    BaseInputArea,
    SelectCollaborateur,
    BaseSlideNumber
  },
  data() {
    return {
      feedback: {},
      mode: null
    };
  },
  props: {
    chiffrage: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    lists: {
      Type: [Object, Array],
      Requierd: true,
      Default: {}
    },
    data: {
      Type: [Object, Array],
      Requierd: true,
      Default: {}
    },
    loading: {
      Type: Boolean,
      Requierd: false,
      Default: false
    }
  },
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),
    ...mapFields("affaires", ["affaire.id"]),

    salaireCharge: function() {
      return Math.round(filterFloat(this.chiffrage.salaire_annuel) * 1.6);
    },

    prixRevient: function() {
      if (this.chiffrage.contrat_id === 1) {
        return Math.round(
          filterFloat(this.salaireCharge) / 218 +
            filterFloat(this.chiffrage.indeminite_journaliere)
        );
      }
      if (this.chiffrage.contrat_id === 2) {
        return Math.round(
          filterFloat(this.salaireCharge) / 228 +
            filterFloat(this.chiffrage.indeminite_journaliere)
        );
      }
      return 0;
    },
    prixRevientTotal: function() {
      return Math.round(
        filterFloat(this.prixRevient) *
          filterFloat(this.chiffrage.jours_prevus) +
          filterFloat(this.chiffrage.frais_divers)
      );
    },
    prixVente: function() {
      return Math.round(
        filterFloat(this.chiffrage.taux_journalier) *
          filterFloat(this.chiffrage.jours_prevus) +
          filterFloat(this.chiffrage.frais_divers) +
          filterFloat(this.chiffrage.frais_directs)
      );
    },
    margeBrute: function() {
      return Math.round(
        ((this.prixVente - this.prixRevientTotal) / this.prixVente) * 100
      );
    },
    margeBruteEuros: function() {
      return Math.round(this.prixVente - this.prixRevientTotal);
    },
    bg: function() {
      return this.$store.getters["colors/colors"].color2;
    }
  },
  methods: {
    store: function() {
      this.chiffrage.chiffrage_type_id = this.mode;
      this.chiffrage.prixVente = this.prixVente;
      this.chiffrage.salaireCharge = this.salaireCharge;
      this.chiffrage.prixRevient = this.prixRevient;
      this.chiffrage.prixRevientTotal = this.prixRevientTotal;
      this.chiffrage.margeBrute = this.margeBrute;
      this.chiffrage.margeBruteEuros = this.margeBruteEuros;
      this.$emit("store");
    },
    selectCollab: function(e) {
      let c = e;
      let filteredContrat = {};
      let filteredVersion = {};
      let activeContrat = {};
      let activeVersion = {};

      filteredContrat = c.collaborateur_contrats.filter(
        contrat => this.$moment(contrat.contrat_date_debut) <= this.$moment()
      );

      if (filteredContrat.length === 0) {
        if (c.collaborateur_contrats.length > 0) {
          activeContrat =
            c.collaborateur_contrats[c.collaborateur_contrats.length - 1];
        } else {
          activeContrat = {};
        }
      } else {
        activeContrat = filteredContrat[filteredContrat.length - 1];
      }

      if (activeContrat && activeContrat.contrat_version) {
        filteredVersion = activeContrat.contrat_version.filter(
          version => this.$moment(version.date_effet) <= this.$moment()
        );

        if (filteredVersion.length === 0) {
          if (activeContrat.contrat_version.length > 0) {
            activeVersion =
              activeContrat.contrat_version[
                activeContrat.contrat_version.length - 1
              ];
          } else {
            activeVersion = {};
          }
        } else {
          activeVersion = filteredVersion[filteredVersion.length - 1];
        }

        if (activeVersion) {
          if (!activeVersion.contrat_brut_annuel) {
            return 0;
          }

          this.chiffrage.salaire_annuel = activeVersion.contrat_brut_annuel;

          if (activeVersion.contrat_statut_id === 1) {
            this.chiffrage.contrat_id = 1;
          } else {
            this.chiffrage.contrat_id = 2;
          }

          if (activeVersion.contrat_brut_annuel) {
            return activeVersion.contrat_brut_annuel;
          } else {
            alert("pas de données de contrat");
            return 0;
          }
        } else {
          return null;
        }
      }
    }
  },
  mounted() {
    if (this.chiffrage && this.chiffrage.chiffrage_type_id) {
      this.mode = this.chiffrage.chiffrage_type_id;
    }
  }
};
</script>
<style lang="css"></style>
